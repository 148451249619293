import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import parse from "html-react-parser";
import Image from "next/image";
import Link from 'next/link';
import nft_main_dark from "../../../public/banner/main-asset.gif";
import top_img_dark from "../../../public/banner/main-asset.gif";

// styles
import styles from "../Home2.module.scss";
import { withTranslation } from "next-i18next";
import { ThemeContext } from "../../../context/Context";
import ConstHelper from "../../../helper/constHelper";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";


function HeaderSection({ content, t, currLocale }) {

    const { currentTheme } = useContext(ThemeContext);
    const [windowWidth] = useState(typeof window !== 'undefined' && window.innerWidth);
    let lightImage, darkImage, lightImageMobile, darkImageMobile, themeImageDesktop, themeImageMobile, themeImageSrcs, themeImageSrcSmallDevice, themeStyles;
    if (currLocale === 'ar') {
        lightImage = "../../../public/img/DropSlider_imgar.jpg";
        darkImage = "../../../public/img/DropSlider_Dark_imgar.jpg";
        lightImageMobile = "../../../public/img/DropSlider_imgmobar.jpg";
        darkImageMobile = "../../../public/img/DropSlider_Dark_imgmobar.jpg";
    } else {
        lightImage = "../../../public/img/DropSlider_img.jpg";
        darkImage = "../../../public/img/DropSlider_Dark_img.jpg";
        lightImageMobile = "../../../public/img/DropSlider_imgmob.jpg";
        darkImageMobile = "../../../public/img/DropSlider_Dark_imgmob.jpg";
    }
    if (currentTheme === ConstHelper.DARK_THEME) {
        themeImageDesktop = darkImage;
        themeImageMobile = darkImageMobile;
        themeImageSrcs = nft_main_dark;
        themeImageSrcSmallDevice = top_img_dark;
        themeStyles = styles.dark;
    }
    // if (currentTheme === ConstHelper.LIGHT_THEME){
    //     themeImageDesktop = lightImage;
    //     themeImageMobile = lightImageMobile;
    //     themeImageSrcs = nft_main_lights;
    //     themeImageSrcSmallDevice = top_img_light;
    //     themeStyles = styles.light;
    // } else{
    //     themeImageDesktop = darkImage;
    //     themeImageMobile = darkImageMobile;
    //     themeImageSrcs = nft_main_dark;
    //     themeImageSrcSmallDevice = top_img_dark;
    //     themeStyles = styles.dark;
    // }
    return (
        <>
            <div className={`position-relative ${styles.saleSlider}`}>
                <Swiper
                    dir={currLocale === "ar" ? "rtl" : "ltr"}
                    // navigation={{
                    //     nextEl: ".swiper9-button-next",
                    //     prevEl: ".swiper9-button-prev",
                    // }}
                    modules={[Navigation, Autoplay]}
                    className="p-b-1rem direCtions"
                    freeMode={true}
                    slidesPerView={1}
                    mousewheel={true}
                    grabCursor={true}
                    speed={1000}
                    autoHeight={true}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                    }}
                    shouldSwiperUpdate={true}
                    rebuildOnUpdate={true}
                >
                    {/* <SwiperSlide>
                        <div className={`${styles.topicOuter}`}>
                            <Link href="/nft/collection/al-fares/drop">
                                <a>
                                    <div className={`${themeStyles}`}>
                                        {windowWidth && windowWidth > 768 &&
                                            <Image
                                                src={themeImageDesktop}
                                                width={1920}
                                                height={512}
                                                layout="fixed"
                                                alt="21 Spades Marketplace"
                                            />
                                        }
                                        {windowWidth && windowWidth < 767 &&
                                            <Image
                                                src={themeImageMobile}
                                                width={375}
                                                height={724}
                                                layout="responsive"
                                                alt="21 Spades Marketplace"
                                            />
                                        }
                                    </div>
                                </a>
                            </Link>
                        </div>
                    </SwiperSlide> */}

                    <SwiperSlide>
                        <section className="section" style={{padding:"64px 0px 0px 0px"}}>
                            <Container>
                                <Row className="align-items-flex-start">
                                    <Col lg={6}>
                                        <div className={styles.mainHead}>
                                            <h1 className={`h2 mainTitle ${styles.mainTitle}`} style={{fontSize:"60px", minHeight:"210px"}}>
                                                {t('common:hero_title')}
                                            </h1>
                                            <div className={styles.desc}>
                                                {content && content.pageContent && content.pageContent !== '' ? parse(content.pageContent) : t('common:hero_desc')}
                                            </div>

                                            <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start">
                                                <Link href="/nft/category">
                                                    <a className="btn">{t('common:explore')}</a>
                                                </Link>
                                                <Link href="/nft/upload-variants">
                                                    <a className="btn buttonStroke mt-sm-0 ms-sm-3">{t('common:create')}</a>
                                                </Link>
                                                <Link href="/feed">
                                                    <a className="btn buttonStroke mt-sm-0 ms-sm-3">Feeds</a>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className={`position-relative ${styles.upyo_top_img}`}>
                                            {windowWidth && windowWidth < 575 &&
                                                <div className={`light ${themeStyles}`}>
                                                    <Image src={themeImageSrcSmallDevice} alt="21 Spades Marketplace" width={544} height={384} layout="responsive" fetchpriority="high" priority />
                                                    <div className={styles.sub_img}>
                                                        {/* <div className={styles.nft_1}><Image src={nft_1} alt="21 Spades Marketplace" width={37} height={47} layout="responsive" loading="lazy" /></div>
                                                        <div className={styles.nft_2}><Image src={nft_2} alt="21 Spades Marketplace" width={47} height={41} layout="responsive" loading="lazy" /></div>
                                                        <div className={styles.nft_3}><Image src={nft_3} alt="21 Spades Marketplace" width={47} height={79} layout="responsive" loading="lazy" /></div>
                                                        <div className={styles.nft_4}><Image src={nft_4} alt="21 Spades Marketplace" width={43} height={95} layout="responsive" loading="lazy" /></div> */}
                                                    </div>
                                                </div>
                                            }
                                            {windowWidth && windowWidth > 575 &&
                                                <div className={`light ${themeStyles}`}>
                                                    <Image src={themeImageSrcs} alt="21 Spades Marketplace" width={544} height={384} layout="responsive" fetchpriority="high" priority />
                                                    <div className={styles.sub_img}>
                                                        {/* <div className={styles.nft_1}><Image src={nft_1} alt="21 Spades Marketplace" width={37} height={47} layout="responsive" loading="lazy" /></div>
                                                        <div className={styles.nft_2}><Image src={nft_2} alt="21 Spades Marketplace" width={47} height={41} layout="responsive" loading="lazy" /></div>
                                                        <div className={styles.nft_3}><Image src={nft_3} alt="21 Spades Marketplace" width={47} height={79} layout="responsive" loading="lazy" /></div>
                                                        <div className={styles.nft_4}><Image src={nft_4} alt="21 Spades Marketplace" width={43} height={95} layout="responsive" loading="lazy" /></div> */}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </SwiperSlide>
                </Swiper>
                <Container className={styles.arrows}>
                    <Row className={`position-relative ${styles.row}`}>
                        {/* <div className="swiper9-button-next"></div>
                        <div className="swiper9-button-prev"></div> */}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default withTranslation()(HeaderSection);